<template>
  <div>
    <!--begin::Members-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Manage Account</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToMembersList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-5">
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <h6>Name:</h6>
                </v-col>
                <v-col cols="12" sm="8">
                  {{ member.first_name }} {{ member.last_name }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <h6>Email:</h6>
                </v-col>
                <v-col cols="12" sm="8">
                  {{ member.email }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <h6>Invitation Status:</h6>
                </v-col>
                <v-col cols="12" sm="8" v-if="member.status == 0">
                  Inactive
                </v-col>
                <v-col cols="12" sm="8" v-else>
                  Active
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <h6>Onboarding Status:</h6>
                </v-col>
                <v-col cols="12" sm="8" v-if="member.status == 0">
                  Not Completed
                </v-col>
                <v-col cols="12" sm="8" v-else>
                  Completed
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <h6>Designation:</h6>
                </v-col>
                <v-col cols="12" sm="8">
                  {{ member.designation }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <h6>Role:</h6>
                </v-col>
                <v-col cols="12" sm="8">
                  <template
                    v-for="(role, index) in role_list"
                  >
                    <span
                      v-bind:key='index'
                      v-if="member.user_type == role.value"
                    >
                      {{ role.text }}
                    </span>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <!--end::Members-->

    <!--begin::Assignment-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Assign to Committee</h3>
        </div>
      </div>
      <div class="card-body" v-if="user_permissions.includes('chapter_club_member_committee_assignments')">
        <!-- <form class="form" novalidate="novalidate" id="kt_assign_committee_form"> -->
          <!--begin::Example-->
          <div class="example mb-5">
            <v-form ref="kt_assign_committee_form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="committee_list"
                    v-model="selected_committee"
                    label="Committee"
                    item-text="name"
                    item-value="id"
                    outlined
                    :rules="[v => !!v || 'Committee is required']"
                    dense
                    class="pa-2"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="options_list"
                    v-model="selected_option"
                    label="Options"
                    item-text="name"
                    :rules="[v => !!v || 'Committee option is required']"
                    item-value="name"
                    outlined
                    dense
                    class="pa-2"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="ml-5"
                  ref="kt_store_submit"
                  @click="addToCommittee()"
                >
                  Add
                </v-btn>
              </v-row>
            </v-form>
          </div>
        <!-- </form> -->
      </div>
      <div class="card-body">
        <v-data-table :headers="committee_header" :items="binded_committee_list">
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.committeeDetails.name }}</td>
              <td>
                {{
                  row.item.is_completed
                    ? "Completed"
                    : row.item.totalCompletedTask + " done out of " + row.item.totalTask
                  }}
              </td>
              <td>
                <span v-if="row.item.options">
                    <v-chip small class="m-1">
                      {{ row.item.options }}
                    </v-chip>
                </span>
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-1"
                      fab
                      small
                      v-on="on"
                      @click="viewTasks(row.item.committeeDetails.id)"
                    >
                      <v-icon color="#B5B5C3">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Task</span>
                </v-tooltip>
                <v-tooltip top v-if="user_permissions.includes('chapter_club_member_committee_assignments')">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-1"
                      fab
                      small
                      v-on="on"
                      @click="removeCommittee(row.item.committeeDetails.id)"
                    >
                      <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove Committee</span>
                </v-tooltip>
                
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <!--end::Members-->

    <!--begin::Manage fees-->
    <div class="card card-custom gutter-b" v-if="user_permissions.includes('chapter_club_member_dues_fees')">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Manage Dues / Fees</h3>
          <div v-if="member.charges_enabled != 'Yes'">
          (Add/Edit Stripe details from Profile page for creating payments.)
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <form class="form" novalidate="novalidate" id="kt_fees_form"> -->
          <!--begin::Example-->
          <div class="example mb-5" v-if="member.charges_enabled=='Yes'">
            <v-form ref="kt_fees_form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="payment_types"
                    v-model="selected_type"
                    label="Payment Type"
                    outlined
                    :rules="[v => !!v || 'Payment Type is required']"
                    dense
                    class="pa-2"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    type="number"
                    v-model="amount"
                    label="Amount"
                    item-text="amount"
                    :rules="[v => !!v || 'Amount is required']"
                    item-value="amount"
                    outlined
                    dense
                    class="pa-2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="note"
                      label="Note"
                      item-text="note"
                      :rules="[v => !!v || 'Note is required']"
                      item-value="note"
                      outlined
                      dense
                      class="pa-2"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    class="ml-5"
                    ref="kt_store_submit"
                    @click="sendPaymentLink()"
                  >
                  Send Payment Link
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        <!-- </form> -->
      </div>
      <div class="card-body">
        <v-data-table :headers="fees_header" :items="binded_fees_list">
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.payment_type }}</td>
              <td>${{ row.item.amount }}</td>
              <td>{{ row.item.status }} 
                <span v-if="row.item.status=='Paid'"> 
                  on {{ row.item.payment_date }}
                </span>
              </td>
              <td>
                <v-tooltip top v-if="row.item.status=='Pending' && member.charges_enabled=='Yes'">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-1"
                      fab
                      small
                      v-on="on"
                      @click="resendPaymentLink(row.item.id)"
                    >
                    <v-icon color="#B5B5C3">mdi-link-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Resend Link</span>
                </v-tooltip>
                <v-tooltip top v-if="row.item.status=='Pending'">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-1"
                      fab
                      small
                      v-on="on"
                      @click="removePayment(row.item.id)"
                    >
                    <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove Payment</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <!--end::Manage fees-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import EventBus from "@/core/services/store/event-bus";
import {
  ADD_TO_COMMITTEE,
  DELETE_MEMBER_COMMITTEE,
  DELETE_EMPLOYEE_PAYMENT,
  SEND_PAYMENT_LINK,
  STORE_PAYMENT
} from "@/core/services/store/committee.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "membersManage",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      user_permissions: [],
      member: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        designation: "",
        user_type: 3,
        charges_enabled:""
      },
      options_list: [],
      valid: true,
      committee_list: [],
      selected_committee: "",
      selected_option: "",
      selected_type: "",
      amount: "",
      note: "",
      role_list: [],
      binded_committee_list: [],
      binded_fees_list: [],
      payment_types : ['Dues','Fees'],
      committee_header: [
        { text: "Committee", align: "left", value: "name", width: "20%" },
        { text: "Assignment Staus", value: "status", width: "40%" },
        { text: "Option", value: "options", width: "20%" },
        { text: "Action", sortable: false, value: "action", width: "20%" }
      ],
      fees_header: [
        { text: "Type", align: "left", value: "payment_type", width: "25%" },
        { text: "Amount", value: "amount", width: "25%" },
        { text: "Status", value: "status", width: "25%" },
        { text: "Action", sortable: false, value: "action", width: "25%" }
      ],
      company_type: "Small Enterprise"
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["getCompany","getUserPermissions"])
  },
  watch: {
    getCompany: function(newVal) {
      this.company_type = newVal.company_type
        ? newVal.company_type
        : "Small Enterprise";
    },
    selected_committee: function(newVal) {
      this.fetchCommitteeOptions(newVal);
    },
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  mounted() {
    let context = this;
    
    //added committee list
    context.getAddedCommitteeList();
    context.getRoleList();
    context.getAddedFeesList();

     EventBus.$on("ADD_TO_COMMITTEE", function(payLoad) {
      if (payLoad) {
        context.getAddedCommitteeList();
      } else {
        Swal.fire("Error", "Error in storing Committee.", "error");
      }
    });

    EventBus.$on("STORE_PAYMENT", function(payLoad) {
      context.getAddedFeesList();
      context.resendPaymentLink(payLoad.id);
    });

    if (this.$route.params.id) {
      axios({
        method: "get",
        url: "member/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.member) {
            context.member = result.data.member;
            context.member.designation = context.member.role;
          } else {
            context.$router.push({ name: "membersList"}); //go back to list
            Swal.fire(result.data.error, "", "error");
          }
        },
        function() {
          context.$router.push({ name: "membersList"}); //go back to list
          Swal.fire("Error", "Members not found!", "error");
        }
      );
    }
    context.getCommitteeList();
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Member" }]);

    const assign_committee_form = KTUtil.getById("kt_assign_committee_form");

    this.fv = formValidation(assign_committee_form, {});
    this.$store.dispatch(GET_COMPANY);
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getRoleList() {
      let context = this;
      axios({
        method: "get",
        url: "committee-roles",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          result.data.committee_roles.forEach(role => {
            context.role_list.push({text: role.name, value: role.id});
          });
        },
        function() {
          Swal.fire(
            "Error",
            "Error in fetching Committee roles list",
            "info"
          );
        }
      );
    },
    viewTasks(committee_id) {
      this.$router.push({ name: "members.tasks",
        params: {
          id: this.$route.params.id,
          committee_id: committee_id
        } 
      });
    },
    removeCommittee(committee_mapper_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Member with Committee!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(
            DELETE_MEMBER_COMMITTEE,
            {
              committee_id: committee_mapper_id,
              user_id: this.$route.params.id
            }
          ).then(() => {
            this.getAddedCommitteeList();
          });
          Swal.fire("Member is Deleted!", "", "success");
        } else if (result.isDismissed) {
          Swal.fire("Member is safe.", "", "info");
        }
      });
    },
    removePayment(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undelete this record!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(
            DELETE_EMPLOYEE_PAYMENT,id
          ).then(() => {
            this.getAddedFeesList();
          });
          Swal.fire("Record is Deleted!", "", "success");
        } else if (result.isDismissed) {
          Swal.fire("Record is not deleted.", "", "info");
        }
      });
    },
    resendPaymentLink(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Payment Link will be sent to employee.",
        showCancelButton: true,
        confirmButtonText: "Send"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(
            SEND_PAYMENT_LINK,
            {
              payment_id: id
            }
          ).then(() => {
            this.getAddedFeesList();
          });
          Swal.fire("Payment Link is sent!", "", "success");
        } else if (result.isDismissed) {
          Swal.fire("Payment Link is not sent!", "", "info");
        }
      });
    },
    fetchCommitteeOptions() {
      let context = this;
      axios({
        method: "post",
        url: "get-committee-options",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: {committee_id : context.selected_committee}
      }).then(
        function(result) {
          context.options_list = [];
          context.selected_option = "";
          if (result.data.committee_options) {
            var options_arr = result.data.committee_options;
            options_arr.forEach(option => {
              context.options_list.push({name: option.option, id: option.id});
            });
          }
        },
        function() {
          context.$router.push({ name: "membersList"}); //go back to list
          Swal.fire("Error", "Options not found!", "error");
        }
      );
    },
    addToCommittee() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.kt_assign_committee_form.validate()) {
        this.$store.dispatch(ADD_TO_COMMITTEE, {
          committee_id: context.selected_committee,
          committee_option: context.selected_option,
          user_id: this.$route.params.id
        });
        context.getAddedCommitteeList();
      } else {
        this.snackbar = true;
      }
      
    },
    sendPaymentLink(){
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.kt_fees_form.validate()) {
        this.$store.dispatch(STORE_PAYMENT, {
          employee_id: this.$route.params.id,
          payment_type: context.selected_type,
          note: context.note,
          amount: context.amount,
        });
        context.getAddedFeesList();
      } else {
        this.snackbar = true;
      }
    },
    getAddedCommitteeList() {
      let context = this;
      axios({
        method: "post",
        url: "get-member-committee-task",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: {user_id: this.$route.params.id}
      }).then(
        function(result) {
          if (result.data.data) {
            context.binded_committee_list =  result.data.data;
          } else {
            Swal.fire(result.data.error, "Error in fetching committee.", "error");
          }
        },
        function() {
          Swal.fire("Error", "Committee fetch error!", "error");
        }
      );
    },
    getAddedFeesList() {
      let context = this;
      axios({
        method: "get",
        url: "employee-payment?employee_id="+this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.employeePayments) {
            context.binded_fees_list =  result.data.employeePayments;
          } else {
            Swal.fire(result.data.error, "Error in fetching Dues / Fees.", "error");
          }
        },
        function() {
          Swal.fire("Error", "Dues / Fees fetch error!", "error");
        }
      );
    },
    redirectToMembersList() {
      let context = this;
      this.$router.push({ name: "membersList" });
    },
    getCommitteeList() {
      let context = this;
      axios({
        method: "get",
        url: "committee",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.committee) {
            var committee_arr = result.data.committee;
            committee_arr.forEach(committee => {
              context.committee_list.push({name: committee.name, id: committee.id});
            });
          }
        },
        function() {
          context.$router.push({ name: "membersList"}); //go back to list
          Swal.fire("Error", "Members not found!", "error");
        }
      );
    }
  }
};
</script>
